<template>
  <layout class="blogs page page-blog" :loading="loading">
    <template #header>
      <h1 class="title-1">Our Blog</h1>
    </template>
    <div class="text-right d-flex justify-content-end blog-top-block mb-3">
      <multiselect
        :options="tagList"
        v-model="tags"
        @input="newSearch"
        @search-change="newSearch"
        placeholder="Search tag"
        label="title"
        track-by="id"
        :multiple="true"
        class="search-tag"
      />

      <div class="search-input search-box">
        <i class="bx bx-search font-size-20 search-icon" />
        <b-input-group>
          <b-form-input
                  v-model="searchModel"
                  placeholder="Search..."
                  @input="newSearch"
          />
        </b-input-group>
      </div>
      <div>
        <a
          v-if="permission"
          class="btn btn-primary d-flex align-items-center"
          @click="handleAdd"
        >
          <i class="fa fa-plus mr-2"></i>
          Add
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" v-for="(item, index) in list.data" :key="index">
        <div class="page-blog__item">
          <div class="image" @click="openNewWindow(item.file)">
            <img :src="item.preview ? url + item.preview : require('@/assets/images/blog/default.png')" alt="">
          </div>
          <div class="preview">
            <div class="preview__header">
              <h4>{{ item.title }}</h4>
            </div>
            <div class="preview__text">
              <p>{{ $shortText(item.description, 60) }}</p>
            </div>
          </div>
          <div class="info" :style="{bottom: permission ? 15+'px' : ''}">
            <span>
              {{ $moment(item.created_at).format('MMMM Do YYYY') }}
            </span>
                          <ul v-if="item.tags && item.tags.length" class="list-inline mb-0">
                            <li class="list-inline-item me-3">
                              <a class="text-muted">
                                <i class="bx bx-purchase-tag-alt align-middle text-muted me-1"></i> <span style="cursor: pointer" class="blog-list-tags" v-for="(tag) in item.tags" :key="tag.id" @click="chooseTag(tag)">{{ tag.title }}</span>
                              </a>
                            </li>
                          </ul>
          </div>
          <div class="action">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <b-button
                    variant="outline-warning"
                    class="shadow-none border-0 edit-btn mr-2"
                    @click="handleEdit(item)"
                    v-if="permission"
                >
                  Edit
                </b-button>
                <b-button
                    variant="outline-danger"
                    class="shadow-none border-0 p-2 mt--2 edit-btn"
                    @click="handleDelete(item.id)"
                    v-if="permission"
                >
                  Delete
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="card mb-0">-->
<!--      <div class="row px-4">-->
<!--        <div v-for="(item, index) in list.data" :key="index" class="col-3">-->
<!--          <div class="card p-1 shadow-none">-->
<!--            <div class="p-3">-->
<!--              <h5><a :href="`/PDF-Flip/index.html?link=${item.file}`" class="text-dark">{{ item.title }}</a></h5>-->
<!--              <small class="text-muted mb-0">{{ item.created_at ? item.created_at.slice(0, 10).split('-').reverse().join('.') : '' }}</small>-->
<!--            </div>-->

<!--            <div v-if="item.preview" class="position-relative text-center">-->
<!--              <a target="_blank§" :href="`/PDF-Flip/index.html?link=${item.file}`">-->
<!--                <img style="cursor:pointer" class="img-thumbnail" :src="url + item.preview" alt="">-->
<!--              </a>-->
<!--            </div>-->

<!--            <div class="p-3">-->
<!--              <ul v-if="item.tags && item.tags.length" class="list-inline">-->
<!--                <li class="list-inline-item me-3">-->
<!--                  <a class="text-muted">-->
<!--                    <i class="bx bx-purchase-tag-alt align-middle text-muted me-1"></i> <span style="cursor: pointer" class="blog-list-tags" v-for="(tag) in item.tags" :key="tag.id" @click="chooseTag(tag)">{{ tag.title }}</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--              <p>{{ item.description }}</p>-->
<!--              <div class="d-flex justify-content-between align-items-center">-->
<!--                <a :href="`/PDF-Flip/index.html?link=${item.file}`" target="_blank" class="text-primary">Show more <i class="mdi mdi-arrow-right"></i></a>-->
<!--                <div>-->
<!--                  <b-button-->
<!--                    variant="outline-warning"-->
<!--                    class="shadow-none border-0 p-2 mt&#45;&#45;2 edit-btn mr-2"-->
<!--                    @click="handleEdit(item)"-->
<!--                    v-if="permission"-->
<!--                  >-->
<!--                    Edit-->
<!--                  </b-button>-->
<!--                  <b-button-->
<!--                    variant="outline-danger"-->
<!--                    class="shadow-none border-0 p-2 mt&#45;&#45;2 edit-btn"-->
<!--                    @click="handleDelete(item.id)"-->
<!--                    v-if="permission"-->
<!--                  >-->
<!--                    Delete-->
<!--                  </b-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <ModalForm
      :modalId="blogModalId"
      :formMode="formMode"
      :initialData="currentRow"
      :modalTitle="modalTitle"
      ref="blogModal"
      @onSubmit="handleBlogFormSubmit"
    />
  </layout>
</template>
<script>

import {FORM_MODE} from "@/utils/constants";
import {convertObjectToFormData} from "@/utils/converters";
import Multiselect from "vue-multiselect";

/**
 * Editors component
 */
export default {
  components: {
    ModalForm: () => ({
      component: import('./blogModalForm')
    }),
    Multiselect,
  },
  data() {
    return {
      blogModalId: 'blog-request-modal',
      modalTitle: 'Add new blog request',
      currentRow: null,
      formMode: '',
      url: process.env.VUE_APP_IMAGE_URL,
      currentPage: 1,
      searchModel: '',
      viewType: 'grid',
      tags: [],
    };
  },
  async mounted() {
    await this.$store.dispatch('blogs/getBlogs', this.queryParams);
    await this.$store.dispatch('blogs/getBlogTags');
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    openNewWindow(file) {
      window.open(`/PDF-Flip/index.html?link=${file}`)
    },
    async newSearch() {
      this.currentPage = 1;
      await this.$store.dispatch('blogs/getBlogs', this.queryParams);
    },
    handleEdit(row) {
      this.currentRow = row;
      this.formMode = FORM_MODE.EDIT;
      this.modalTitle = 'Update blog request';
      this.$bvModal.show(this.blogModalId);
    },
    handleDelete(id) {
      this.$bvModal.msgBoxConfirm('Delete this item?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            await this.$store.dispatch('blogs/deleteBlog', { id, data: convertObjectToFormData({ _method: 'DELETE' })});
            await this.$store.dispatch('blogs/getBlogs');
          }
        });
    },
    handleAdd() {
      this.modalTitle = 'Add new blog request';
      this.currentRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.blogModalId);
    },
    async handleBlogFormSubmit(data) {
      this.$refs.blogModal.setLoading(true);
      const formData = new FormData()
      if (data.title) formData.append('title', data.title)
      if (data.description) formData.append('description', data.description)
      if (data.preview) formData.append('preview', data.preview)
      if (data.file) formData.append('file', data.file)
      data.tags.forEach((tag, index) => {
        formData.append(`tags[${index}]`, tag.id)
      })
      let isSuccess = true
      if (this.formMode === FORM_MODE.CREATE) {
        await this.$store.dispatch('blogs/createBlog', formData)
          .then((response) => {
            if (!response || response.code !== 200) {
              isSuccess = false
            }
          });
      }
      if (this.formMode === FORM_MODE.EDIT) {
        formData.append('_method', 'PUT')
        await this.$store.dispatch('blogs/updateBlog', {
          id: this.currentRow.id,
          data: formData
        })
          .then((response) => {
            if (!response || response.code !== 200) {
              isSuccess = false
            }
          });
      }
      if (isSuccess) {
        await this.$store.dispatch('blogs/getBlogs');
        this.$refs.blogModal.setLoading(false);
        this.$bvModal.hide(this.blogModalId);
      }
    },
    async handleScroll() {
      const height = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );
      if (
        window.pageYOffset + 200 >= height - document.documentElement.clientHeight
        && this.currentPage < this.list.last_page
        && !this.loading
      ) {
        ++this.currentPage;
        await this.$store.dispatch('blogs/getBlogs', this.queryParams);
      }
    },
    chooseTag(tag) {
      if (!this.tags.some(e => e.id === tag.id)) {
        this.tags.push(tag)
        this.newSearch()
      }
    }
  },
  computed: {
    list() {
      return this.$store.getters['blogs/blogList'];
    },
    tagList() {
      return this.$store.getters['blogs/blogTagList'];
    },
    user() {
      return this.$store.state['user'];
    },
    loading() {
      return this.$store.state.blogs.loading;
    },
    queryParams() {
      const tags = this.tags.map(tag => tag.id)
      return {
        page: this.currentPage,
        tags,
        search: this.searchModel || null
      }
    },
    permission() {
      return this.$hasAccess(['admin', 'hrm']);
    }
  }
};
</script>
<style lang="scss">
.search-tag {
  max-width: 300px;
}
  .blog-top-block{
    .search-box{
      position: relative;
      width: 300px;
      margin: 0 10px;
      .search-icon {
        position: absolute;
        z-index: 1;
        top: 9px;
        left: 15px;
      }
      input{
        padding-left: 40px;
        border: 2px solid #e4e6ec;
      }
    }
    .multiselect__tags{
      display: flex;
      padding: 8px 40px 7px 10px !important;
    }
    .multiselect__tags-wrap{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

.blogs {
  .img-thumbnail {
    max-width: 100%;
    background-color: unset;
    border: unset;
  }
  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    justify-content: space-between;
    &__input {
      width: 218px;
      position: relative;
      img {
        top: 50%;
        right: 9px;
        position: absolute;
        pointer-events: none;
        transform: translateY(-50%);
      }
      input {
        height: 37px;
        outline: none;
        width: 100%;
        color: #555555;
        padding: 0 11px;
        font-size: 14.4px;
        border: 2px solid #e4e6ec;
        cursor: pointer;

        &::-webkit-input-placeholder {
          color: #555555;
        }
        &::-moz-placeholder {
          color: #555555;
        }
        &:-ms-input-placeholder {
          color: #555555;
        }
        &:-moz-placeholder {
          color: #555555;
        }
      }
    }
  }
}
.nav-item {
  cursor: pointer;
}
.blog-list-tags:not(:last-child):after {
  content: ', '
}
</style>
